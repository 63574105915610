// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin container() {
  max-width: 1000px;
  margin: 0px auto;
  width: 90%;
  
  @include breakpoint(medium){
    width: 90%;
  }
  @include breakpoint(large){
    width: 85%;
  }
}

@mixin container-small() {
  max-width: 1280px;
  margin: 0px auto;
  width: 90%;
}

@mixin container-large() {
  margin: 0px auto;
  width: 95%;
}
