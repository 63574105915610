.mobile-nav {
  width: 100%;
  height: 44px;
  padding: 0% 5% 0% 5%;
  @include hide-for(medium);
  
  &--red {
    background-color: $red;
  }
  
  &--orange {
    background-color: $orange;
  }
  
  &--purple {
    background-color: $purple;
  }
  
  &__expand {
    display: block;
    width: 150px;
    float: left;
    color: $white;
    padding-top: 11px;
    
    &:hover {
      color: $white;
    }
    
    img {
      margin-top: -3px;
      margin-right: 4%; 
    }
  }
  
  &__logo {
    display: block;
    float: right;
    padding-top: 9px;
  }
}


.mobile-nav-expanded {
  
  position: fixed;
  z-index: 10;
  top: 0px;
  height: 0%;
  overflow: hidden;
  width: 100%;
  transition: height 0.3s;
  
  &.active {
    height: 100%;
  }
  
  &__top-bar {
    height: 44px;
    background-color: $dark-grey;
    padding: 0% 5% 0% 5%;
  }
  
  &__logo {
    display: block;
    float: right;
    padding-top: 9px;
  }
  
  &__close {
    float: left;
    padding-top: 9px;
  }
  
  &__links {
    position: relative;
    width: 100%;
    height: calc(100% - 44px);
    margin-bottom: 44px;
    overflow: hidden;
    
    &__link {
      
      display: flex;
      justify-content:center;
      align-content:center;
      flex-direction:column;
      position: relative;
      width: 100%;
      height: 20%;
      color: $white;
      transition: all 0.3s;
      font-size: 18px;
      text-align: center;
      
      &:hover {
        color: $white;
      }
      
      &--red {
        background-color: $red;
        &:hover {
          background-color: scale-color($red, $lightness: -7%);
        }
      }
      &--orange {
        background-color: $orange;
        &:hover {
          background-color: scale-color($orange, $lightness: -7%);
        }
      }
      &--purple {
        background-color: $purple;
        &:hover {
          background-color: scale-color($purple, $lightness: -7%);
        }
      }
      &--blue {
        background-color: $blue;
        &:hover {
          background-color: scale-color($blue, $lightness: -7%);
        }
      }
      &--green {
        background-color: $green;
        &:hover {
          background-color: scale-color($green, $lightness: -7%);
        }
      }
    }
  }
  
}


.contact-bar {
  width: 100%;
  height: 44px;
  background-color: $medium-grey;
  @include show-for(medium);
  
  &__container {
    @include container-large();
  }
  
  &__link {
    color: $near-white;
    display: inline-block;
    padding-top: 9px;
    font-family: $font-family--alt;
    font-size: 16px;
    width: auto;
    margin-right: 5%;
    transition: all 0.3s;
    
    img {
      margin-top: -1px;
      margin-right: 14px;
    }
    
    &:hover {
      color: $white;
      text-decoration: underline;
    }
    
    &--vcard {
      img {
        margin-top: -4px;
        margin-right: 14px;
      }
    }
  }
  
  &__logo {
    display: block;
    float: right;
    padding-top: 8px;
  }
}


.medium-nav {
  
  height: 110px;
  @include show-for(medium);
  
  &__links {
    
    padding: 0px;
    margin: 0px;
    
    &__link {
      width: 20%;
      padding: 0px;
      margin: 0px;
      float: left;
      text-align: center;
      display: inline-block;
      list-style-type: none;
      height: 100px;
      line-height: 98px;
      color: $white;
      transition: all 0.3s;
      font-size: 18px;
      color: $white;
      
      @include breakpoint(large){
        font-size: 26px;
      }
      
      &.active {
        height: 110px;
        line-height: 108px;
        font-family: $font-family--bold;
        color: $white;
      }
      
      &:hover {
        color: $white;
        height: 110px;
        line-height: 108px;
      }
      
      &:visited { 
        color: $white;
      }
      
      &--red {
        background-color: $red;
      }
      &--orange {
        background-color: $orange;
      }
      &--purple {
        background-color: $purple;
      }
      &--blue {
        background-color: $blue;
      }
      &--green {
        background-color: $green;
      }
    }
  }
}