// --------------------------------------------------
// Layout index page
// --------------------------------------------------


.container {
  @include container();
  margin-top: 30px;
  
  @include breakpoint(medium){
    margin-top: 40px;
  }
  
  @include breakpoint(large){
    margin-top: 50px;
  }
}

.page-content {
  
  font-size: 12px;
  
  @include breakpoint(medium){
    font-size: 13px;
  }
  
  @include breakpoint(large){
    font-size: 14px;
  }
  
  h1 {
    display: block;
    padding-top: 30px;
    margin-bottom: 20px;
    margin-top: 30px;
    border-top: 1px solid #BABABA 
  }
  
  h2 {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  blockquote {
    font-family: $font-family--italic;
    font-size: 11px;
    border-left: none;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    
    @include breakpoint(medium){
      font-size: 12px;
    }
    @include breakpoint(large){
      font-size: 13px;
    }
  }
  
  a {
    font-family: $font-family--bold;
  }
  
  
  blockquote {
    .expand-quote {
      background-image: url(../img/more-icon.svg);
      background-repeat: no-repeat;
      background-position: right top 2px;
      padding-right: 15px;
      color: $light-grey;
    }
    .colapse-quote {
      background-image: url(../img/less-icon.svg);
      background-repeat: no-repeat;
      background-position: right top 2px;
      padding-right: 15px;
      color: $light-grey;
    }
  }

}


.mobile-contact {
  
  padding-top: 5px;
  padding-bottom: 5px;

  @include hide-for(medium);
  
  &__link {
    margin-top: 20px;
    display: block;
    font-family: $font-family--alt;
    color: $light-grey;
    padding-left: 35px;
    position: relative;
    
    img {
      display: block; 
      position: absolute;
      left: 0px;
      top: 4px;
      z-index: 0;
    }
    
    &--vcard {
      img {
        top: 0px;
        left: 2px;
      }
    }
  }
  
}

.footer {
  @include container();
  margin-top: 60px;
  text-align: center;
  font-family: $font-family--alt;
  font-size: 13px;
  margin-bottom: 40px;
  color: #b1b1b1;
  line-height: 1.7;
  
  &__logo {
    display: block;
    margin: 0px auto;
    margin-bottom: 15px;
  }
}
